import React from 'react';

const OrderNowBtn = () => (
  <a
    className="btn primary-btn"
    href="https://mybetterlunch.ahotlunch.com/login"
  >
    Order
  </a>
);

export default OrderNowBtn;
